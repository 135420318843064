import axios, { type AxiosInstance } from "axios";

// export default axios.create({
//     baseURL: import.meta.env.VITE_BASEURL + "auth",
//     headers: {
//         "Content-type": "application/json"
//     }
// })

const authClient: AxiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BASEURL + "admin-v2",
    headers: {
        "Content-Type": "application/json"
    }
});

export const httpBlob = axios.create({
	baseURL: import.meta.env.VITE_BASEURL + "admin-v2",
	responseType: 'blob',
});

export default authClient;