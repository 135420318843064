import { defineStore } from "pinia";
import authService from "@/services/auth.service";
import { useUserStore } from './user';
import http from '@/http-common'
import router from "@/router";
import { redirectByLoginUrl } from '@/utils/auth.utils';
import { useLocalStorage } from '@vueuse/core';
import { useUserTours } from './userTours';

export const useAuthStore = defineStore( 'auth', {
    state: () => ({
        authToken: localStorage.getItem('authToken') ?? '',
        username: localStorage.getItem( "username" ) ?? "",
        isAdmin: useLocalStorage('isAdmin', false)
    }),
    actions: {
        async login( username: string, password: string ){
            try{
                const result = await authService.userLogin( username, password );
                this.isAdmin = result.isAdmin || false;
                console.log('🚀 ~ login ~ result:', result)
                const tokenResult = result.accessToken;
    
                localStorage.setItem('authToken', JSON.stringify( tokenResult ) );
                localStorage.setItem( "username", username )
                this.authToken = JSON.stringify( tokenResult );
                this.username = username;

                await router.isReady();
                if( result.guest ){
                    router.push( `${result.loginUrl}` )
                } else if (result?.type === 'prestador'){
                    const userStore = useUserStore();
                    userStore.logInAsPrestador(result);
                    router.push(result.loginUrl);
                    return;
                } else if( result?.type === 'audio-tours' ){
                    const userStore = useUserStore();
                    userStore.logInAsPrestador(result);
                    router.push(result.loginUrl);
                    return;
                } else{
                    redirectByLoginUrl([{loginUrl: result.loginUrl, loginUrlMatch: 'reservas', to: import.meta.env.VITE_DASHBOARD_URL, payload:{token: result.accessToken, username: result.username}}])
                    console.log( result.loginUrl );
                    if( result.loginUrl === '/localizadores' ){
                        router.push("/localizadores");
                    }else{
                        router.push( '/home' );
                    }
    
                }
                
                return tokenResult;
            } catch( err ){
                console.log('🚀 ~ login ~ err:', err)
                throw err;
            }
            // return authToken;
            // this.authToken = JSON.stringify( authToken );
        },
        async logout( navigateToLogout: boolean = true ){
            localStorage.removeItem('authToken');
            localStorage.removeItem('username');
            this.authToken = "";
            this.username = "";

            //igual se actualiza en el localstorage
            this.isAdmin = false;
            
            if( navigateToLogout ){
                await router.push('/login');
            }
            return "logout";
            // return "";
        },
        async checkIfStillValid(){
            try{
                const result = await http.get("/auth/check-if-still-valid", {
                    headers: {
                        "Authorization": `Bearer ${this.authToken}`
                    }
                })
                return result;
            } catch( error ){
                throw error;
            }
        },
        // Método para verificar y restaurar el estado de autenticación
        async checkAuthState() {
            try {
                // Si hay un token almacenado, restauramos el estado
                const storedToken = localStorage.getItem('authToken');
                const storedUsername = localStorage.getItem('username');
                
                if (storedToken && storedUsername) {
                    this.authToken = storedToken;
                    this.username = storedUsername;
                    
                    // Intentar validar el token si es necesario
                    try {
                        await this.checkIfStillValid();
                    } catch (error) {
                        console.error('Token no válido, cerrando sesión');
                        this.logout(false);
                    }
                }
            } catch (error) {
                console.error('Error al verificar estado de autenticación:', error);
            }
        }
    }
})