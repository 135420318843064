import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import 'vue3-toastify/dist/index.css';
import 'mdb-vue-ui-kit/css/mdb.min.css'
import './assets/main.css';
import 'mdb-vue-file-upload/css/mdb-vue-file-upload.min.css';

import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImprovedLayout from './layouts/ImprovedLayout.vue'
import LoaderComponent from './components/General/LoaderComponent.vue'
import PrimeVue from "primevue/config";
import 'primevue/resources/themes/lara-light-blue/theme.css';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { faBars, faWarning, faGlobe ,faCartArrowDown, faHouse, faPenSquare, faBoxes, faDollarSign, faTicketAlt, faCalendarCheck, faBox, faArrowCircleLeft, faPen, faTrash, faMinus, faPlus, faImage, faArrowUpRightFromSquare, faQrcode, faArrowUpFromBracket, faLanguage, faUpload, faFileAudio, faGear, faFloppyDisk, faMicrophone, faClock, faUser, faCalendar  } from '@fortawesome/free-solid-svg-icons'

import VueGoogleMaps from '@fawmi/vue-google-maps'

import VCalendar from "v-calendar";
import "v-calendar/style.css";

import Tooltip from 'primevue/tooltip';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';

const app = createApp(App)

import primevueLocaleEs from "@/locale/es.json";

library.add( faBars, faImage,faWarning, faMinus, faPlus , faGlobe , faCartArrowDown,  faHouse, faPenSquare, faBoxes, faPen, faTrash ,faDollarSign, faTicketAlt, faCalendarCheck, faBox, faArrowCircleLeft, faArrowUpRightFromSquare, faQrcode, faArrowUpFromBracket, faLanguage, faUpload, faFileAudio,faGear, faFloppyDisk, faMicrophone, faClock, faUser, faCalendar);
app.use(Vue3Toastify, { autoClose: 3000, theme: 'colored' } as ToastContainerOptions);
app.use(createPinia())
app.use(router)
app.use(VueGoogleMaps, {
    load: {
        key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
        libraries: "places"
    }
})

app.use( PrimeVue, {
    locale: {...primevueLocaleEs.es}
} );

app.use( VCalendar, {});
app.use(ConfirmationService);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component( 'ImprovedLayout', ImprovedLayout );
app.component( "LoaderComponent", LoaderComponent );
app.component('v-select', vSelect );
app.directive('tooltip', Tooltip );
app.component('confirmPopup', ConfirmPopup );
app.mount('#app')
